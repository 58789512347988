
:root {
  --colorThemePrimary: #F85431;
  --colorThemePrimaryText:#ffffff;
  --colorThemeSecondary:#232323;
  --colorThemeSecondaryText:#ffffff;
  /* Header Colors */
  --primaryHeaderBgclr: #fafafa;
  --primaryHeaderTextclr: #000000;
  --secondaryHeaderBgclr: #fafafa;
  --secondaryHeaderTextclr: #000000 ;
  /* Footer Colors */
  --primaryFooterBgClr: #232323;
  --primaryFooterTextClr:#ffffff;
  /* Buttons Colors */
  --colorPrimaryBtnText:#ffffff ;
  --colorPrimaryBtnBackground:#F85431 ;
  --colorPrimaryBtnDisabledBackground: #dd6145;
  --colorPrimaryBtnBackgroundHover: #f74620;

  --colorSecondaryBtnText:#ffffff;
  --colorSecondaryBtnBackground:#232323;
  --colorOutlineBtnText:#ffffff;
  --colorOutlineBtnBackground:#000000;
  /* Fonts */
  --primaryFont: 'Poppins';
  --secondaryFont: 'Montserrat';
  --tertiaryFont: 'Assistant';
  /* Search Banners Colors */
  --colorIcons:var(--colorThemePrimary) ;
  --footerBackgroundColor:#232323;
  --footerTextColor:#ffffff;
}

*, 
*::before, 
*::after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  scroll-behavior: smooth;
  font-family: var(--primaryFont);
}

h1,.h1 {
  font-size: 1.5rem;
  margin: 0px 0px 15px 0px;
  font-family: var(--primaryFont);
}
h2, h3, .h2 , .h3 {
  font-size: 1.125rem;
  margin: 0px 0px 15px 0px;
  font-family: var(--primaryFont);
}
img {
  max-width: 100%;
  display: block;
}
a {
  text-decoration: none;
  color: inherit;
}
a{
  cursor:pointer;
  text-decoration:none;
  color:inherit;
}
p{
  margin: 0 0 15px 0;
  word-break: break-word;
}

hr{
  margin: 0;
  border: 1px solid lightgray;
  width: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

input{
  border: 1px solid lightgray;
}

input[type='email'], 
input[type='submit'] {
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  border: 0;
  font-size: inherit;
  font-family: inherit;
}
input[type='email'] {
  width: 100%;
}
button{
  cursor: pointer;
}
button[disabled],
button.disabled{
  cursor:not-allowed;
  cursor: not-allowed;
}

input:is(:focus){
  outline:none;
}


@media (min-width: 1024px) {
  h1 {
      font-size: 2.5rem;
      line-height: 1.5;
 }
  h2 {
      font-size: 2rem;
      line-height: 1.25;
 }
  h3 {
      font-size: 1.25rem;
 }
}

.btn {
  font-weight: 600;
  padding:12px 20px;
  border:1px solid;
  border-color: #fafafa;
  cursor: pointer;
  outline:none;
  display:flex;
  text-decoration: none;
  justify-content:center;
  text-transform: uppercase;
  border-radius: 4px;
  align-items: center;
  font-family: var(--primaryFont);
  letter-spacing: 1px;
  background-color: #F85431;
  color: #ffffff;
}
.btn--link{
  color:inherit;
}
.btn--primary{
  color: var(--colorPrimaryBtnText);
  background-color: var(--colorPrimaryBtnBackground);
  border-color: var(--colorPrimaryBtnBackground);
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
}
.btn--primary:disabled,
.btn--primary:disabled:hover{
  background-color: var(--colorPrimaryBtnDisabledBackground);
  border-color: var(--colorPrimaryBtnDisabledBackground);
}
.btn--secondary{
  color: var(--colorSecondaryBtnText);
  background-color: var(--colorSecondaryBtnBackground);
}
.btn:hover, 
.btn:focus {
  outline:none;
}
.btn--primary:hover, 
.btn--primary:focus {
  outline:none;
  color: var(--colorPrimaryBtnText);
  background-color: var(--colorPrimaryBtnBackgroundHover);
}
.btn--secondary:hover, 
.btn--secondary:focus {
  outline:none;
  color: var(--colorPrimaryBtnText);
  background-color: #000000;
}
.btn--outline{
  background-color: var(--colorOutlineBtnText);
  color: var(--colorOutlineBtnBackground);
  border: 1px solid var(--colorOutlineBtnBackground);
}
.btn--full{
  width:100%;
}
.btn--svg {
  background:none;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn--loading{
  display: flex;
  align-items: center;
  gap: 10px;
}


a.button {
  font-size: 1rem;
  min-width: 100px;
}

.icon{
  width:30px;
  height:30px;
  vertical-align: middle;
  display: inline-block;
  fill: inherit;
}

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

.one-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1; 
  -webkit-box-orient: vertical;
}
.two-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
}



#sidebarMenu .sidebar__navigation ul::-webkit-scrollbar {
  width: 3px;
}

#sidebarMenu .sidebar__navigation ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #000; 
  border-radius: 8px;
}
 
#sidebarMenu .sidebar__navigation ul::-webkit-scrollbar-thumb {
  background: var(--colorThemePrimary); 
  border-radius: 8px;
}
#sidebarMenu .sidebar__navigation ul::-webkit-scrollbar-thumb:hover {
  background: var(--colorThemePrimary); 
} 



.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App .page--templates{
  min-height: 40vh;
}


/* RTE */

.rte{
  margin-bottom:7.5px;
}
.rte ul{
  list-style-type: disc;
  list-style-position: inside;
}

.rte:after{clear:both;content:"";display:table;}

@media only screen and (min-width:769px){

.rte{
    margin-bottom:15px;
}
  }

.rte:last-child{
    margin-bottom:0;
  }

.rte+.rte{
    margin-top:30px;
  }

.rte ol,.rte p,.rte table,.rte ul{
    margin-bottom:15px;
  }

@media only screen and (min-width:769px){

.rte ol,.rte p,.rte table,.rte ul{
      margin-bottom:25px;
  }
    }

.rte ol:last-child,.rte p:last-child,.rte table:last-child,.rte ul:last-child{
      margin-bottom:0;
    }

.rte ul ul{
      margin-bottom:0;
    }

.rte h1,.rte h2,.rte h3,.rte h4,.rte h5,.rte h6{
    margin-bottom:25px;
    margin-top:60px;
    word-break: break-word;
  }

.rte h1:first-child,
.rte h2:first-child,
.rte h3:first-child,
.rte h4:first-child,
.rte h5:first-child,
.rte h6:first-child{
      margin-top:0;
    }

.rte h1 a,.rte h2 a,.rte h3 a,.rte h4 a,.rte h5 a,.rte h6 a{
      -webkit-text-decoration:none;
      text-decoration:none;
    }

.rte meta:first-child+h1,
.rte meta:first-child+h2,
.rte meta:first-child+h3,
.rte meta:first-child+h4,
.rte meta:first-child+h5,
.rte meta:first-child+h6{
      margin-top:0;
    }

.rte>div{
    margin-bottom:15px;
  }

.rte>div:last-child{
      margin-bottom:0;
    }

.rte li{
    margin-bottom:0;
  }

.rte table{
    table-layout:fixed;
  }


.rte-setting>p:last-child,
.rte > p:last-child{
    margin-bottom:0;
  }

.rte a,.rte-setting a{
    -webkit-text-decoration:none;
    text-decoration:none;
  }

.rte img,.rte-setting img{
    height:auto;
  }

.rte blockquote,blockquote{
  margin:0;
  padding:15px 30px 40px;
}

.rte blockquote p,blockquote p{
    margin-bottom:0;
  }

.rte blockquote p+cite,blockquote p+cite{
      margin-top:15px;
    }

.rte blockquote cite,blockquote cite{
    display:block;
  }

  .red{
    color: var(--colorThemePrimary);
  }

  .line {
    margin: 1rem;
  }

  @keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg)
  }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: .75s linear infinite spinner-grow;
    animation: .75s linear infinite spinner-grow
}

.bx-spin {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite
}

.send-button {
  border-radius: 3px;
  transition: background .2s linear;
  background: #F85431;
  height: 44px;
  width: 125px;
  overflow: hidden;
  position: relative;
  display: flex;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.send-button::after{
    position: absolute;
    background: rgba(black, .2);
    transform: scale(0);
    height: 44px;
    width: 44px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    content: '';
    border-radius: 50%;
}

.send-button:hover{
    cursor: pointer;
}
.send-button:hover span{
    transform: translateX(0);
}

.send-button:not(:active){
  background: darken(#00B288, 5%);
}

.send-button:not(:active)::after{
  animation: ripple .4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.send-button .left {
  float: left;
}

.send-button span {
  transform: translateX(-125px);
  transition: transform .2s cubic-bezier(0.785, 0.135, 0.15, 0.86); 
  height: 44px;
  flex-basis: 125px;
  min-width: 125px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.payment-processing-timer{
  color: #F85431;
  font-size: 30px;
}

.swal2-loading{
  display: block !important;
  text-align: center;
}

.swal2-loader{
  margin: auto;
  margin-bottom: 1rem;
}

@keyframes ripple {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(4);
  }
}
